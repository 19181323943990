import React from "react"


export default function ContactForm () {
    return (
        <>
            <section id="our-products">
                <div className="card">
                    <iframe title="Career" frameborder="0" style={{height:"700px", width: "99%", border: "none", backgroundColor: "#1d536b"}} src='https://forms.zohopublic.com/xccelerata1/form/contact2/formperma/edVEAvAYY6u6bMmIRe7GowGtSScv39Eai-mFrN54O0M'></iframe>
                </div>




            </section>


        </>
    )
}
