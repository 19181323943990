import React from "react"


export default function HeroCareer() {
  return (
    <section className="clearfix " className="container " className="hero-img container-home" data-aos="">
      <img style={{ width: "100%",}} src="./assets/img/careerbanner.png" alt="" />

    </section>
  )
}