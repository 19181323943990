import React from "react"

export default function ServicesCareers() {
    return (
        <>
            <section id="why-us" className="clearfix">

                <div className="row">
                    <div className="col-md-10 info">
                       
                        <p className="founder-p-text" style={{ color: "white", textAlign: "left",}}>
                         
                        Are you a Professional Caregiver who takes pride in the work ethic, integrity and commitment you bring? Are you seeking a professional opportunity that recognizes the contribution you make to families in the community? Do you excel abovthe rest in your commitmentto your profession?
                               
                        </p>
                        <p className="founder-p-text" style={{ color: "white", textAlign: "left",}}>
                         
                        Unified Home Care is an international organization; with more than 3 years experience providing privately-paid, in-home supportive care services to all t.he age groups and gender, older adults and disabled adults in their homes, wh.e.r.ever home is for them.
                               
                        </p>
                        <p className="founder-p-text" style={{ color: "white", textAlign: "left",}}>
                         
                        Our independently-owned local offfices alre always looking to add to their team of special individuals who can offer a highly professional approach and commitment to our client families and to Unified Home Care.
                                
                         </p>
                         <p className="founder-p-text" style={{ color: "white", textAlign: "left",}}>
                         
                         All of our Unified Home Care Team members are incredible! They are passionate, patient, caring and committed. Most of all, they bring pride and a professional work ethic to  everything they do.
                                
                         </p>
 

                    </div>


                </div>

            </section>


        </>
    )
}
