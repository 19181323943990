import React from "react"


export default function BottomCareers() {
    return (
        <>
            <section id="our-products1">
                <div className="card">

                    <h3>Join us for the most exciting journey in Home Care Industry</h3>

                </div>




            </section>


        </>
    )
}
