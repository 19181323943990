import React from "react"
import Header from '../components/Header'
import Navigation from '../components/Navigation'

import BottomCareers from "../components/Bottom Careers"
import ServicesCareers from "../components/Services Careers"
import ContactForm from "../components/Contact Form"
import HeroCareer from "../components/HeroCareer"




export default function careers() {



  return (
    <>
      <Header />
      <Navigation />
      <HeroCareer/>
      {/* <Hero/> */}
      <main id="main">
          
      <BottomCareers/>
      <ServicesCareers/>
<ContactForm/>
      </main>


    </>
  )
}